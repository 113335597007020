import React from "react"

class AboutShort extends React.Component {

    constructor(props){
        super(props);
    }
/*
<p>
                <strong>Is it time to</strong> learn the secrets of global interconnection, of staying in touch, of travelling, and of connecting to people across distance and culture?
                </p>
                <p> Managing our time and interactions with other people throughout the day is difficult enough,
                    but what about all the problems that arise when you start involving people in a different city, country, and timezone?
                    How do we communicate, coordinate, and congregate with people from all around the world who each have their own lives and priorities?
                </p>
                <p>
                    <em>Is it time to</em> is here to help anyone that works across different parts of the globe at the same time.</p>
                <p>
                    Expats, frequent flyers, employees of multinational corporations, if you have someone important to you 
                    in another part of the world, we're here to offer tips, hints, strategies, and tools to help you communicate.
                </p>
*/
    render() {
        return (
            <div className="simple-post">
                <h2>About Landing Last Minute</h2>
                <p className="py-1">
                  <em>Landing Last Minute.</em>
                </p>
                <p className="mb-1">
                  We help people travel easily. We want to provide the information that you
                  need to travel easily.
                  To travel to your next destination.
                  To discover the world.
                  To reduce the confusion, the headaches, the stress of visiting a new destination.
                </p>
                <p className="py-1"><em>How do we help you travel?</em></p>
                <p>We write guides.
                  Guides to help you find your way.
                  Guides about the best ways to enjoy yourself while you travel.
                  Guides to help you make the best decisions quickly.</p>
                <h2>About Chris</h2>
                <p>
                    <em>Landing last minute</em>... and looking for coffee, that sounds like me.
                    I've nearly missed flights before due to coffee.
                </p>
                <p>
                    In my post-flight pre-coffee haze I've found it challenging to navigate airports:
                    How do I get my phone working? Which way is the exit? Should I get coffee now or
                    when I get to the city? I fly a lot and this happens to me often.
                </p>
                <p>
                    My name's Chris. I've lived and worked all over the world, and I like staying in touch with my friends and family.
                </p>
                <p>
                    I contribute to this site to help people...</p>
                    <ul>
                        <li>maximise their travel,</li>
                        <li>stay in touch around the world, and</li>
                        <li>discover how the world is big and complicated place.</li>
                    </ul>
                
            </div>
        )
    }
}

export default AboutShort