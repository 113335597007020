import React from "react"
import { Link, graphql } from "gatsby"

import Img from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutShort from "../components/aboutshort"

function ArticleCard(props){
  return(
    <div className="mx-auto px-1 py-2 max-w-md my-3">
      <Link to={props.destination}>
        <div className="bg-white shadow rounded-lg mb-6 tracking-wide">
          <div className="md:flex-shrink-0">
            {props.children}
          </div>
          <div className="px-4 py-2 mt-2">
            <h2 className="font-bold text-2xl text-gray-800 tracking-normal">
              {props.title}
            </h2>
            <p className="text-sm text-gray-700 px-2 mr-1">
              {props.description}
            </p>
            <div className="flex items-center justify-between mt-2 mx-6">
              <Link to={props.destination} className="bold border-b-2 border-indigo hover:text-blue-300">Read more</Link>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Read Guides"
        />

        <div className="container mx-auto max-w-5xl">
          <h1 className="my-4 text-2xl md:text-3xl lg:text-5xl leading-tight">
            All Guides By Landing Last Minute
          </h1>
        </div>
        <section className="bg-gray-100 border-b py-8">
          <div className="container mx-auto max-w-5xl flex flex-wrap md:pt-2 pb-12">
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              var i = <div />
              if (node.frontmatter.cover_image) {
                i = <Img
                      fluid={node.frontmatter.cover_image.childImageSharp.fluid}
                      alt={node.frontmatter.cover_image_caption}
                      className="w-full h-32 md:h-64 rounded-lg rounded-b-none"
                    />
              }
              return (
                <ArticleCard
                  title = {title}
                  destination = {node.fields.slug}
                  description = {node.excerpt}
                >
                  <Img
                    fluid={node.frontmatter.cover_image.childImageSharp.fluid}
                    alt={node.frontmatter.cover_image_caption}
                    className="w-full h-64 p-5 rounded-lg rounded-b-none"
                  />
                </ArticleCard>
              )
            })}
          </div>
        </section>

        <div className="container max-w-5xl mx-auto md:flex items-start py-4 lg:px-12 md:px-0">
          <div className="w-full">


            {/*posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              var i = <div />
              if (node.frontmatter.cover_image){
                i = <Img 
                      fluid={node.frontmatter.cover_image.childImageSharp.fluid} 
                      alt={node.frontmatter.cover_image_caption}
                    />
              }
              return (
                <div key={node.fields.slug} className="mb-8 flex flex-wrap shadow-lg">
                  <div className="lg:w-3/4 w-full p-4">
                    <h3 className="mb-4 cursor-pointer p-1 border-l-4 border-indigo">
                      <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                        {title}
                      </Link>
                    </h3>
                    <div className="text-grey-700 leading-normal"><div dangerouslySetInnerHTML={{ __html: node.excerpt }}></div></div>
                    <p className="text-blue-600"><Link className="button" to={node.fields.slug}>Read more</Link></p>
                  </div>
                  
                  <div className="lg:w-1/4 w-full bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden">
                    <p>
                      {i}
                    </p>
                  </div>
                </div>
              )
              })*/}

          </div>
        </div>
          
      </Layout>
    )
  }
}
/*
<div className="container max-w-4xl mx-auto text-center flex flex-wrap items-start md:flex-no-wrap">
          <div className="col-lg-8 col-sm-12">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            var i = <div />
            if (node.frontmatter.cover_image){
              i = <Img 
                    fluid={node.frontmatter.cover_image.childImageSharp.fluid} 
                    alt={node.frontmatter.cover_image_caption}
                  />
            }
            return (
              <div key={node.fields.slug} className="my-4 w-full md:w-1/3 flex flex-col items-center justify-center px-4">
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <h3>
                      <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                        {title}
                      </Link>
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                    <p><Link className="button inverse small" to={node.fields.slug}>Read more</Link></p>
                  </div>
                  
                  <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden">
                    <p>
                      {i}
                    </p>
                  </div>
                </div>
                
                
                
              </div>
            )
          })}
          </div>
          
          </div>
*/

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 160)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            cover_image {
              childImageSharp {
                resize (width:1500, height: 1500) {
                  src
                }
                fluid (maxWidth: 480) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            cover_image_caption
          }
        }
      }
    }
  }
`
